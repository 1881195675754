import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CarouselProvider, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import styled from 'styled-components';
import _ from 'lodash';
import { IconButton } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Link from 'next/link';
import { useRouter } from 'next/router';
import moment from 'moment';
import LazyLoad from 'react-lazyload';

import { theme } from '../../../utils/theme';
import { Image } from '../common-components';
import { WordPressImageTransformer } from '../../helpers/wordpress-image-transformer';
import useResponsive from '../../helpers/responsive';
import { eventService } from '../../services/homii-services';

const CustomButtonBack = React.forwardRef((props, ref) => (
    <IconButton component={ButtonBack} ref={ref} {...props} />
));

CustomButtonBack.displayName = 'CustomButtonBack';

const CustomButtonNext = React.forwardRef((props, ref) => (
    <IconButton component={ButtonNext} ref={ref} {...props} />
));

CustomButtonNext.displayName = 'CustomButtonNext';

const MobileBlogCarousel = (props) => {
    const { isMobile, isSmallMobile } = useResponsive();
    const { data, title, showAll } = props;
    const [blogPostCount, setBlogPostCount] = useState(0);
    const [relatedTags, setRelatedTags] = useState([]);
    const [blogData, setBlogData] = useState([]);

    const router = useRouter();
    const currentSlug = _.get(router, 'query.slug');

    const nextButtonRight = 9;
    const backButtonRight = 11;

    useEffect(() => {
        _.forEach(data, (post) => {
            if (_.get(post, 'acf.url_slug') === currentSlug) {
                setRelatedTags(_.get(post, 'acf.meta_data_tags'));
            }
        });
    }, []);

    useEffect(() => {
        const blogPosts = [];
        if (showAll) {
            data.filter(
                (x) =>
                    x.acf.meta_data_categories !== false &&
                    x.acf.meta_data_categories.map((_) => _.category === 'Blog'),
            ).map((_) => blogPosts.push(_));
        } else {
            const _relatedTags = relatedTags.map((_) => _.tag);
            const _blogs = data.filter(
                (x) =>
                    x.acf.meta_data_categories !== false &&
                    x.acf.meta_data_categories.map((_) => _.category === 'Blog') &&
                    x.acf.url_slug !== currentSlug &&
                    x.acf.meta_data_tags !== false,
            );

            _.forEach(_blogs, (_blog) => {
                const _blogTags = _blog.acf.meta_data_tags.map((_) => _.tag);
                _.forEach(_blogTags, (_tag) => {
                    if (_relatedTags.includes(_tag)) {
                        blogPosts.push(_blog);
                    }
                });
            });
        }
        setBlogData(blogPosts);
        setBlogPostCount(_.size(blogPosts));
    }, [relatedTags]);

    const checkCategory = (categories) => {
        let isBlog = false;
        if (!categories) {
            return isBlog;
        }
        _.forEach(categories, (category) => {
            if (category.category === 'Blog') {
                isBlog = true;
            }
        });
        return isBlog;
    };

    const renderBackButton = (right) => {
        return (
            <StyledCustomButtonBack
                id="blog-carousel-back"
                key="back"
                aria-label="Back"
                right={right}
            >
                <StyledArrowBackRoundedIcon />
            </StyledCustomButtonBack>
        );
    };

    const renderNextButton = (right) => {
        return (
            <StyledCustomButtonNext
                id="blog-carousel-next"
                key="next"
                aria-label="Next"
                right={right}
            >
                <StyledArrowForwardRoundedIcon />
            </StyledCustomButtonNext>
        );
    };

    const checkForTags = (tags) => {
        return _.map(tags, (tag, index) => {
            const type = _.toUpper(_.get(tag, 'tag'));
            return <span key={index}>{type}</span>;
        });
    };

    const onPostClicked = (slug) => {
        eventService.logCustomEvent('viewed-blog-post', {
            description: `User viewed ${slug} and was directed from home page`,
            slug,
        });
    };

    const renderCarouselItem = () => {
        const sortedPostByDate = blogData.sort(
            (a, b) => b.meta_data_publish_date - a.meta_data_publish_date,
        );
        return _.map(sortedPostByDate, (item, index) => {
            const blogTitle = _.get(item, 'acf.seo_title');
            const slug = _.get(item, 'acf.url_slug');
            const tags = _.get(item, 'acf.meta_data_tags');
            const featuredImagePre = _.get(item, 'acf.featured_image');
            const featuredImage = WordPressImageTransformer(featuredImagePre, 490, 100);
            const featuredImageAlt = _.get(item, 'acf.featured_image_alt');
            const postPublished = _.get(item, 'acf.meta_data_publish_date');
            const categories = _.get(item, 'acf.meta_data_categories');

            const isBlog = checkCategory(categories);
            if (!isBlog) {
                return null;
            }

            return (
                <SlideWrapper key={index}>
                    <Slide index={index} key={index} className="slide-container">
                        <Link href={`/blog/posts/${slug}`} as={`/blog/posts/${slug}`} passHref>
                            <StyledAnchorTag target="_blank">
                                <div>
                                    <LazyLoad>
                                        <StyledImage
                                            src={featuredImage}
                                            alt={featuredImageAlt || 'blog-article'}
                                            width={392}
                                            height={549}
                                            id={`${slug}-carousel-button`}
                                            animate
                                            isContainerButton
                                            containerWidth="90%"
                                            containerHeight="70%"
                                            onClick={() => onPostClicked(slug)}
                                        />
                                    </LazyLoad>
                                </div>
                            </StyledAnchorTag>
                        </Link>

                        <CaptionContainer>
                            <span>
                                <Caption>{checkForTags(tags)}</Caption>
                            </span>
                            <PublishedDate>
                                {moment(postPublished).format('DD.MM.YYYY')}
                            </PublishedDate>
                        </CaptionContainer>
                        <StyledParagraph
                            className="selected-blog"
                            size={16.8}
                            color={theme.paragraphLight}
                        >
                            {blogTitle}
                        </StyledParagraph>
                    </Slide>
                </SlideWrapper>
            );
        });
    };

    const renderAllButtonsMobile = () => {
        if (isMobile && blogPostCount > 1) {
            return (
                <MobileButtonsContainer isSmallMobile={isSmallMobile}>
                    <NavButtonContainerMobile>
                        {renderBackButton(backButtonRight)}
                        {renderNextButton(nextButtonRight)}
                    </NavButtonContainerMobile>
                </MobileButtonsContainer>
            );
        }
        return null;
    };

    if (!isMobile) {
        return null;
    }
    return (
        <CarouselContainer>
            <HeaderRow>
                <Header2>{title || 'Featured Articles'}</Header2>
                <Link href="/blog" passHref>
                    <AnchorTag id="blog_link">Read all</AnchorTag>
                </Link>
            </HeaderRow>
            <CarouselProvider
                id="mobile-blog-carousel"
                naturalSlideWidth={1}
                naturalSlideHeight={2}
                totalSlides={blogPostCount}
                visibleSlides={1.3}
                orientation="horizontal"
                dragEnabled
                touchEnabled
                infinite
            >
                <Slider aria-label="blog-carousel">{renderCarouselItem()}</Slider>
                {renderAllButtonsMobile(backButtonRight, nextButtonRight)}
            </CarouselProvider>
        </CarouselContainer>
    );
};

export default MobileBlogCarousel;

MobileBlogCarousel.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    showAll: PropTypes.bool,
};

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const PublishedDate = styled.span`
    font-size: 11.7px;
    margin-right: 15%;
`;

const Caption = styled.span`
    color: ${(props) => props.theme.primaryLight};
`;

const CaptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    color: white;
`;

const MobileButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${({ isSmallMobile }) => (isSmallMobile ? 120 : 0)}px;
`;

const AnchorTag = styled.a`
    color: ${(props) => props.theme.primaryLight};
    margin-top: 15px;
    margin-right: 5px;
`;

const Header2 = styled.h2`
    color: white;
`;

const NavButtonContainerMobile = styled.div`
    align-self: center;
    margin-top: -150px;
`;

const StyledCustomButtonBack = styled(CustomButtonBack)`
    right: ${(props) => props.right}%;
`;

const StyledCustomButtonNext = styled(CustomButtonNext)`
    border: 2px solid ${(props) => props.theme.carouselButtonBorder};
    border-radius: 50%;
    right: ${(props) => props.right}%;
`;

const StyledArrowBackRoundedIcon = styled(ArrowBackRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.carouselButtonBorder};
`;

const StyledArrowForwardRoundedIcon = styled(ArrowForwardRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.paragraphLight};
`;

const CarouselContainer = styled.div`
    padding-top: 77px;
`;

const SlideWrapper = styled.div`
    .slide-container:hover .selected-blog {
        color: ${(props) => props.theme.primaryLight};
    }

    .focusRing___1airF.carousel__slide-focus-ring {
        outline: none !important;
        pointer-events: none;
    }
`;

const StyledImage = styled(Image)`
    border-radius: 5px;
    object-fit: cover;
`;

const StyledAnchorTag = styled.a`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
`;

const StyledParagraph = styled.p`
    font-size: ${(props) => props.size}px;
    color: ${(props) => props.color};
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 25px;
`;
