import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MobileBlogCarousel from './mobile-blog-carousel.component';
import DesktopBlogCarousel from './desktop-blog-carousel.component';
import { cmsService } from '../../services/homii-services';

const BlogSection = (props) => {
    const { page, title, showAll, tagId, pageSize } = props;

    const [blogData, setBlogData] = useState(null);

    useEffect(() => {
        const getBlogs = async () => {
            const respBlog = await cmsService.ListWordPressBlogPosts(tagId, pageSize, null);
            setBlogData(_.get(respBlog, 'blogs'));
        };
        getBlogs();
    }, [page]);

    if (!blogData) {
        return null;
    }

    return (
        <Section>
            <MobileBlogCarousel data={blogData} title={title} showAll={showAll} />
            <DesktopBlogCarousel data={blogData} title={title} showAll={showAll} />
        </Section>
    );
};

export default BlogSection;

BlogSection.propTypes = {
    page: PropTypes.any,
    title: PropTypes.string,
    showAll: PropTypes.bool,
    tagId: PropTypes.number,
    pageSize: PropTypes.number,
};

const Section = styled.div`
    background-color: ${(props) => props.theme.sectionBackgroundDark};
`;
