import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CarouselProvider, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import styled from 'styled-components';
import _ from 'lodash';
import { IconButton, Grid } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Image } from '../common-components';
import { WordPressImageTransformer } from '../../helpers/wordpress-image-transformer';
import useResponsive from '../../helpers/responsive';
import { eventService } from '../../services/homii-services';

const CustomButtonBack = React.forwardRef((props, ref) => (
    <IconButton component={ButtonBack} ref={ref} {...props} />
));

CustomButtonBack.displayName = 'CustomButtonBack';

const CustomButtonNext = React.forwardRef((props, ref) => (
    <IconButton component={ButtonNext} ref={ref} {...props} />
));

CustomButtonNext.displayName = 'CustomButtonNext';
const DesktopBlogCarousel = (props) => {
    const { isMobile, isTablet } = useResponsive();
    const { data, title, showAll } = props;
    const [blogPostCount, setBlogPostCount] = useState(0);
    const [relatedTags, setRelatedTags] = useState([]);
    const [blogData, setBlogData] = useState([]);

    const router = useRouter();
    const currentSlug = _.get(router, 'query.slug');

    const nextButtonRight = 11;
    const backButtonRight = 14;
    let leftGridSize = 2;
    let rightGridSize = 10;
    let marginTop = 110;

    if (isTablet) {
        leftGridSize = false;
        rightGridSize = 12;
        marginTop = 20;
    }

    useEffect(() => {
        _.forEach(data, (post) => {
            if (_.get(post, 'acf.url_slug') === currentSlug) {
                setRelatedTags(_.get(post, 'acf.meta_data_tags'));
            }
        });
    }, []);

    useEffect(() => {
        const blogPosts = [];
        if (showAll) {
            data.filter(
                (x) =>
                    x.acf.meta_data_categories !== false &&
                    x.acf.meta_data_categories.map((_) => _.category === 'Blog'),
            ).map((_) => blogPosts.push(_));
        } else {
            const _relatedTags = relatedTags.map((_) => _.tag);
            const _blogs = data.filter(
                (x) =>
                    x.acf.meta_data_categories !== false &&
                    x.acf.meta_data_categories.map((_) => _.category === 'Blog') &&
                    x.acf.url_slug !== currentSlug &&
                    x.acf.meta_data_tags !== false,
            );

            _.forEach(_blogs, (_blog) => {
                const _blogTags = _blog.acf.meta_data_tags.map((_) => _.tag);
                _.forEach(_blogTags, (_tag) => {
                    if (_relatedTags.includes(_tag)) {
                        blogPosts.push(_blog);
                    }
                });
            });
        }

        setBlogData(blogPosts);
        setBlogPostCount(_.size(blogPosts));
    }, [relatedTags]);

    const checkCategory = (categories) => {
        let isBlog = false;
        if (!categories) {
            return isBlog;
        }
        _.forEach(categories, (category) => {
            if (category.category === 'Blog') {
                isBlog = true;
            }
        });
        return isBlog;
    };

    const renderBackButton = (right) => {
        return (
            <StyledCustomButtonBack
                id="blog-carousel-back"
                key="back"
                aria-label="Back"
                right={right}
            >
                <StyledArrowBackRoundedIcon />
            </StyledCustomButtonBack>
        );
    };

    const renderNextButton = (right) => {
        return (
            <StyledCustomButtonNext
                id="blog-carousel-next"
                key="next"
                aria-label="Next"
                right={right}
            >
                <StyledArrowForwardRoundedIcon />
            </StyledCustomButtonNext>
        );
    };

    const onPostClicked = (slug) => {
        eventService.logCustomEvent('viewed-blog-post', {
            description: `User viewed ${slug} and was directed from home page`,
            slug,
        });
    };

    const checkForTags = (tags) => {
        return _.map(tags, (tag, index) => {
            const type = _.toUpper(_.get(tag, 'tag'));
            return <span key={index}>{type}</span>;
        });
    };

    const renderCarouselItem = () => {
        const sortedPostByDate = blogData.sort(
            (a, b) => b.meta_data_publish_date - a.meta_data_publish_date,
        );
        return _.map(sortedPostByDate, (item, index) => {
            const blogTitle = _.get(item, 'acf.seo_title');
            const slug = _.get(item, 'acf.url_slug');
            const tags = _.get(item, 'acf.meta_data_tags');
            const featuredImagePre = _.get(item, 'acf.featured_image');
            const featuredImage = WordPressImageTransformer(featuredImagePre, 500, 100);
            const featuredImageAlt = _.get(item, 'acf.featured_image_alt');
            const postPublished = _.get(item, 'acf.meta_data_publish_date');
            const categories = _.get(item, 'acf.meta_data_categories');

            const isBlog = checkCategory(categories);
            if (!isBlog || slug === currentSlug) {
                return null;
            }

            return (
                <SlideWrapper key={index}>
                    <Slide index={index} key={index} className="slide-container">
                        <Link href={`/blog/posts/${slug}`} as={`/blog/posts/${slug}`} passHref>
                            <StyledAnchorTag target="_blank">
                                <div>
                                    <StyledImage
                                        src={featuredImage}
                                        alt={featuredImageAlt || 'blog-article'}
                                        width={462}
                                        height={427}
                                        id={`${slug}-carousel-button`}
                                        animate
                                        isContainerButton
                                        containerWidth="90%"
                                        containerHeight="80%"
                                        onClick={() => onPostClicked(slug)}
                                    />
                                </div>
                            </StyledAnchorTag>
                        </Link>

                        <CaptionContainer>
                            <span>
                                <Caption>{checkForTags(tags)}</Caption>
                            </span>
                            <PublishedDate>
                                {_.upperCase(moment(postPublished).format('DD MMMM YYYY'))}
                            </PublishedDate>
                        </CaptionContainer>
                        <TitleParagraph className="selected-blog">{blogTitle}</TitleParagraph>
                    </Slide>
                </SlideWrapper>
            );
        });
    };

    const renderAllButtons = () => {
        let navButtonsMarginTop = 0;
        if (isMobile || blogPostCount < 2) {
            return null;
        }
        if (isTablet) {
            navButtonsMarginTop = 20;
        }
        return (
            <ButtonsContainer margintop={navButtonsMarginTop}>
                {renderBackButton(backButtonRight)}
                {renderNextButton(nextButtonRight)}
            </ButtonsContainer>
        );
    };

    const renderCarousel = () => {
        if (isMobile) {
            return null;
        }
        return (
            <Grid container>
                <Grid item xs={leftGridSize}>
                    <HeaderContainer>
                        <div>
                            <Header4>{title || 'Featured Articles'}</Header4>
                            <Link href="/blog" passHref>
                                <AnchorTag id="blog_link">Read all</AnchorTag>
                            </Link>
                        </div>
                    </HeaderContainer>
                </Grid>

                <StyledGrid item xs={rightGridSize}>
                    <CarouselContainer top={marginTop}>
                        <CarouselProvider
                            id="blog-carousel"
                            naturalSlideWidth={1}
                            naturalSlideHeight={1.3}
                            totalSlides={blogPostCount}
                            visibleSlides={2.2}
                            orientation="horizontal"
                            className="carousel-wrapper"
                            dragEnabled={false}
                            touchEnabled={false}
                            infinite
                        >
                            <Slider aria-label="blog-carousel">{renderCarouselItem()}</Slider>
                            {renderAllButtons()}
                        </CarouselProvider>
                    </CarouselContainer>
                </StyledGrid>
            </Grid>
        );
    };

    return renderCarousel();
};

export default DesktopBlogCarousel;

DesktopBlogCarousel.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    showAll: PropTypes.bool,
};

const PublishedDate = styled.span`
    margin-left: 40px;
`;

const Caption = styled.span`
    color: ${(props) => props.theme.primaryLight};
`;

const CaptionContainer = styled.div`
    font-size: 11px;
    margin-top: 35px;
    color: white;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-self: center;
    margin-top: ${(props) => props.margintop}px;
`;

const AnchorTag = styled.a`
    color: ${(props) => props.theme.primaryLight};
`;

const Header4 = styled.h4`
    display: flex;
    justify-content: center;
    margin-top: 85px;
    color: white;
    word-wrap: normal;
    width: 50%;
    margin-bottom: 30px;
`;

const TitleParagraph = styled.h4`
    margin-top: 24px;
    color: white;
    margin-right: 60px;
`;

const StyledCustomButtonBack = styled(CustomButtonBack)`
    right: ${(props) => props.right}%;
    margin-top: 20px;
`;

const StyledCustomButtonNext = styled(CustomButtonNext)`
    border: 2px solid ${(props) => props.theme.carouselButtonBorder};
    border-radius: 50%;
    margin-top: 20px;
    right: ${(props) => props.right}%;
`;

const StyledArrowBackRoundedIcon = styled(ArrowBackRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.carouselButtonBorder};
`;

const StyledArrowForwardRoundedIcon = styled(ArrowForwardRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.paragraphLight};
`;

const SlideWrapper = styled.div`
    .slide-container:hover .selected-blog {
        color: ${(props) => props.theme.primaryLight};
    }

    .focusRing___1airF.carousel__slide-focus-ring {
        outline: none !important;
        pointer-events: none;
    }
`;

const StyledImage = styled(Image)`
    border-radius: 5px;
    object-fit: cover;
`;

const StyledAnchorTag = styled.a`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
`;

const HeaderContainer = styled.div`
    padding-left: 20px;
    padding-top: 80px;
    margin-bottom: 20px;
`;

const CarouselContainer = styled.div`
    padding-left: 0px;
    margin-top: ${(props) => props.top}px;
`;

const StyledGrid = styled(Grid)`
    margin-bottom: 100px;
`;
